<template>
    <div class="container pl-0" style="margin-bottom: 30px;">
        <div class="insurance-history__menu__filters" >
            <button @click="filterStatusWrap()" style="width: 120px;text-align: left">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="4" width="24" height="2" fill="#24282C"/>
                    <rect y="18" width="24" height="2" fill="#24282C"/>
                    <circle cx="8" cy="5" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                    <circle cx="16" cy="19" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                </svg>
                {{ condition.isShowFilter ? 'Приховати' : 'Фільтри'}}
            </button>
        </div>
        <transition name="fade">
            <div v-show="condition.isShowFilter" class="menu filters mt-4">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <div class="filter-title" style="">
                        Тип продукту
                    </div>
                    <el-select v-model="listQuery.companyTypeId"
                               class="form-control-el"
                               multiple placeholder="Виберіть тип продукту">
                        <el-option v-for="item in this.$store.getters.START_COMPANY_TYPE"
                                   :key="item.id"
                                   :label="item.name"
                                   :type="item.type"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-sm-4 form-group">
                    <div class="filter-title">Пошук за назвою страхової компанії</div>
                    <div>
                        <input class="form-control" v-model="listQuery.insurerName"/>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <button @click="getList()" class="btn pl-0 w-100 btn-primary">
                        Застосувати
                    </button>
                </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
    import { userMixin, partnerMixin, companyMixin, orderMixin } from '@/mixin'
    import {list as listCompanies} from "@/api/company";
    export default {
        mixins: [userMixin, partnerMixin, companyMixin, orderMixin],
        name: 'FilterBlock',
        props: ['isLoadXml', 'isLoadData', 'orders'],
        data: function () {
            return {
                dateFilter: new Date(),
                condition: {
                    isShowBlock: false,
                    isShowFilter: false,
                    isShowChildrenUser:false
                },

                listQuery: {
                    companyTypeId: null,
                    insurerName: null,
                },
            }
        },
        created() {

        },
        mounted: function () {

        },
        computed: {

        },
        methods: {
            getQuery() {
                return this.listQuery;
            },

            filterStatusWrap: function(scroll = false) {
                this.condition.isShowFilter = ! this.condition.isShowFilter;

                if (scroll) {
                    let filter = this.$el.querySelector(".insurance-history__menu__filters");
                    filter.scrollTop = filter.scrollHeight;
                }
            },
            getList() {
                this.condition.isShowFilter = false;
                return this.$emit('init-orders-list');
            },
            initOrdersList: function() {
                return this.$emit('init-orders-list');
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>
