<template>
    <div>
        <div class="modal fade" tabindex="-1" role="dialog" id="updateCommissionModal" aria-labelledby="commissionModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="commissionModalLabel">Оновити значення комісії</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="![3,2,5].includes(typeId)" class="col-md">
                            <label>Знижка (%)</label>
                            <input v-model="newDiscount" v-mask="'##'" class="form-control" :class="{'is-invalid': hasError('discount') }">
                            <div v-if="hasError('discount')" class="invalid-feedback">
                                {{ getError('discount') }}
                            </div>
                        </div>
                        <div v-if="[2].includes(typeId)" class="col-md">
                          <label>Кешбек (%)</label>
                          <input v-model="newCashBack" v-mask="'##'" class="form-control" :class="{'is-invalid': hasError('cashback') }">
                          <div v-if="hasError('cashback')" class="invalid-feedback">
                            {{ getError('cashback') }}
                          </div>
                        </div>
                        <div v-if="! isLandingUserType(tariff.partnerTypeId)" class="col-md">
                            <label>Комісія (%)</label>
                            <input v-model="newCommission" v-mask="'##'" class="form-control" :class="{'is-invalid': hasError('commission') }">
                            <div v-if="hasError('commission')" class="invalid-feedback">
                                {{ getError('commission') }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Скасування</button>
                        <button @click="updateTariff()" type="button" class="btn btn-primary">Оновити</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { updateUserTariff } from "@/api/tariff";
import { userMixin} from "@/mixin";

export default {
    name: "updateCommissionModal",
    props: ['commission', 'discount', 'cashback', 'updateTariffData', 'typeId'],
    mixins: [userMixin],
    data: function () {
        return {
            errors: [],
            newCommission: this.commission,
            newDiscount: this.discount,
            newCashBack: this.cashback,
            tariff: {},
        };
    },
    methods: {
        clearErrors() {
          this.errors = null;
        },
        getError(key) {
            return this.errors[key][0]; //return first error
        },
        hasError(key) {
            return _.has(this.errors, key);
        },
        updateTariff() {
            this.clearErrors();
            let data = this.tariff;
            data.commission = this.newCommission;
            data.discount = this.newDiscount;
            data.cashback = this.newCashBack;

            updateUserTariff(data)
                .then(response => {
                    if (response.data) {
                        this.$emit('initList', false);

                        $('#updateCommissionModal').modal('hide');
                    }
                })
                .catch(e => {
                    this.errors = e.response.data.data;
                });
        }
    },
    watch: {
        commission(value) {
            this.clearErrors();
            this.newCommission = value;
        },
        discount(value) {
            this.clearErrors();
            this.newDiscount = value;
        },
        cashback(value) {
            this.clearErrors();
            this.newCashBack = value;
        },
        updateTariffData(object) {
            this.clearErrors();
            this.tariff = object;
        }
    }
}
</script>

<style scoped>

</style>
