import { render, staticRenderFns } from "./updateDgoCommissionModal.vue?vue&type=template&id=215e598c&scoped=true&"
import script from "./updateDgoCommissionModal.vue?vue&type=script&lang=js&"
export * from "./updateDgoCommissionModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215e598c",
  null
  
)

export default component.exports