import { render, staticRenderFns } from "./PenaltySetting.vue?vue&type=template&id=930374c8&scoped=true&"
import script from "./PenaltySetting.vue?vue&type=script&lang=js&"
export * from "./PenaltySetting.vue?vue&type=script&lang=js&"
import style0 from "./PenaltySetting.vue?vue&type=style&index=0&id=930374c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "930374c8",
  null
  
)

export default component.exports