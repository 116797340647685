<script>
import {changePenaltySetting} from "@/api/partner";

export default {
    name: "PenaltySetting",
    computed: {
        partnersList() {
            return this.$store.getters.ALL_PARTNERS;
        },
    },
    data() {
      return {
          errorMessage: null,
      }
    },
    methods: {
        handleCancel() {
            return this.$store.dispatch('GET_ALL_PARTNERS');
        },
        handleOk(bvModalEvent, partner) {
            bvModalEvent.preventDefault()
            this.saveTariff(partner.id, partner.penaltySetting.isActive, partner.penaltySetting.commission)
        },
        switchBonusPenalty(partner, status){
            this.saveTariff(partner.id, status, partner.penaltySetting.commission)
        },
        saveTariff(partnerId, status, commission) {
            changePenaltySetting(partnerId, status, commission).then(response => {
                this.errorMessage = null;

                this.$nextTick(() => {
                    this.$bvModal.hide('penalty-'+ partnerId)
                })

                return this.$store.dispatch('GET_ALL_PARTNERS');
            }).catch(error => {
                this.errorMessage = error.response.data
            })
        }
    }
}
</script>

<template>
    <div class="insurance-history__list">
        <div class="insurance-history__list__preview collapsed" data-toggle="collapse" data-target="#penalties"
             aria-expanded="false" aria-controls="penalties">
            Штрафи
            <div class="arrow">
                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.2795 2.75059e-08L11 0.69496L5.5 6L-2.31891e-07 0.694961L0.7205 4.45343e-07L5.50275 4.61273L10.2795 2.75059e-08Z"
                        fill="#009BEB"/>
                </svg>
            </div>
        </div>
        <div class="insurance-history__list__container">
            <div id="penalties" class="insurance-history__list__content show-plus-icon collapse">
                <div class="insurance-history__nav">
                    <span class="insurance-history__nav__item">Тип продукту</span>
                    <span class="insurance-history__nav__item">Комісія, %</span>
                </div>
                <div class="insurance-history__product">
                    <div class="insurance-history__list__item">Штрафи</div>
                    <div class="insurance-history__list__item percent">{{ $store.state.userInfo.userInfo.commissionPenalty }} <i style="margin-left: 5px;" v-b-tooltip="'Комісія'">?</i></div>
                </div>

                <div class="insurance-history__product__user">
                    <div class="insurance-history__product__user__list">
                        <ul>
                            <li v-for="partner in partnersList">
                                <div class="row">
                                    <div style="width: 37%">
<!--                                        <div>-->
                                            <input type="checkbox"
                                                   :id="'penalty' + partner.id"
                                                   @input="switchBonusPenalty(partner, $event.target.checked)"
                                                   :checked="partner.penaltySetting.isActive"
                                            >
                                            <label :for="'penalty'+partner.id">
                                                {{ partner.fullName }}
                                            </label>
<!--                                        </div>-->
                                    </div>
                                    <div style="width: auto; margin-right: 25px"
                                         class="insurance-history__list__item percent">
                                        <span class="descr">Комісія:</span>
                                        <span style="cursor:pointer" @click="$bvModal.show('penalty-'+partner.id)">
                                            <b>{{ partner.penaltySetting.commission }}</b>
                                        </span>
                                        <b-modal :id="'penalty-'+partner.id"
                                                 title="Оновити значення комісії"
                                                 @ok="handleOk($event, partner)"
                                                 @cancel="handleCancel"
                                                 cancel-title="Скасування"
                                                 ok-title="Оновити"
                                        >

                                            <label>Комісія (%)</label>
                                            <input v-model="partner.penaltySetting.commission" v-mask="'##'"
                                                   class="form-control"
                                                   :class="{'is-invalid': errorMessage }"
                                            >
                                            <div v-if="errorMessage" class="invalid-feedback">
                                                {{ errorMessage }}
                                            </div>
                                        </b-modal>
                                        <i v-b-tooltip="'Комісія при оплаті штрафу'">?</i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
* >>> .modal-backdrop {
    opacity: .5;
}
</style>
